import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useEffect } from 'react'
import Dashboard from './pages/Dashboard'
import RegisterUser from './pages/RegisterUser'
import Login from './pages/Login'
import ResponsiveAppBar from './components/AppBar'
import ForgotPass from './pages/ForgotPass'
import { useAuthContext } from './hooks/useAuthContext'
import HomePage from './pages/HomePage'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Profile from './pages/auth/Profile'
import { lime, purple } from '@mui/material/colors';
import Comms from './pages/auth/Comms'
import AccountsDash from './pages/auth/Accounts/AccountsDash'
import TuitionPayment from './pages/auth/Accounts/TuitionPayment'
import TuitionReturn from './pages/auth/Accounts/TuitionReturn'
import TransactionLog from './pages/auth/Accounts/TransactionLog'
import AccountsAccess from './pages/auth/Accounts/AccountsAccess'
import AccountStatus from './pages/auth/Accounts/AccountStatus'
import CamDash from './pages/auth/cam/CamDash'
import CamApply from './pages/auth/cam/CamApply'
import CamReturn from './pages/auth/cam/CamReturn'
import CamEntryStatus from './pages/auth/cam/CamEntryStatus'
import DCExamDash from './pages/auth/dcexam/DCExamDash'
import DCApply from './pages/auth/dcexam/DCApply'
import DCReturn from './pages/auth/dcexam/DCReturn'
import DCEntryStatus from './pages/auth/dcexam/DCEntryStatus'
import Results from './pages/auth/Results'
import Timetable from './pages/auth/Timetable'
import MTS from './pages/auth/MTS'
import Absence from './pages/auth/Absence'
import ExamTimetable from './pages/auth/ExamTimetable'
import Registrations from './pages/auth/Registrations'
import Resources from './pages/auth/Resources'
import CIERules from './pages/CIERules'
import Terms from './pages/Terms'
import Conduct from './pages/Conduct'
function App() {




  const theme = createTheme({
    palette: {
      primary: {
        main: '#2b3a42'

      },
      secondary: {
        main: '#e91e63'
      },
    },

  });

  const { user,
    authIsReady,
    accessLevel,
    accountHolder,
    shareAccount,
    distance,
    fulltime,
    parentID
  } = useAuthContext()




  return (
    <>

      {
        authIsReady && (
          <ThemeProvider theme={theme}>

            <BrowserRouter>
              {user && (<ResponsiveAppBar />)}
              <Routes>

                <Route path="/" element={parentID ? <Dashboard /> : <HomePage />} />
                <Route path="/login" element={parentID ? <Navigate to="/" /> : <Login />} />
                <Route path="/forgotpassword" element={parentID ? <Navigate to="/" /> : <ForgotPass />} />
                <Route path="/register" element={parentID ? <Navigate to="/" /> : <RegisterUser />} />

                <Route path="/cierules" element={<CIERules />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/tnc" element={<Terms />} />
                <Route path="/conduct" element={<Conduct />} />


                <Route path="/profile" element={parentID ? <Profile /> : <Navigate to="/" />} />
                <Route path="/comms" element={parentID ? <Comms /> : <Navigate to="/" />} />
                <Route path="/accounts" element={parentID ? <AccountsDash /> : <Navigate to="/" />} />
                <Route path="/accounts/tuitionpayments" element={parentID ? <TuitionPayment /> : <Navigate to="/" />} />
                <Route path="/accounts/tuitionreturn/:id" element={authIsReady ? <TuitionReturn /> : <Navigate to="/" />} />
                <Route path="/accounts/transactionlogs" element={parentID ? <TransactionLog /> : <Navigate to="/" />} />
                <Route path="/accounts/access" element={accountHolder ? <AccountsAccess /> : <Navigate to="/" />} />
                {(shareAccount || accountHolder) && (
                  <Route path="/accounts/status" element={parentID ? <AccountStatus /> : <Navigate to="/" />} />

                )}
                <Route path="/cambridge" element={parentID ? <CamDash /> : <Navigate to="/" />} />
                <Route path="/cambridge/apply" element={parentID ? <CamApply /> : <Navigate to="/" />} />
                <Route path="/cambridge/status" element={parentID ? <CamEntryStatus /> : <Navigate to="/" />} />
                <Route path="/cambridge/return/:id" element={authIsReady ? <CamReturn /> : <Navigate to="/" />} />
                <Route path="/results" element={parentID ? <Results /> : <Navigate to="/" />} />
                <Route path="/registrations" element={parentID ? <Registrations /> : <Navigate to="/" />} />

                <Route path="/resources" element={parentID ? <Resources /> : <Navigate to="/" />} />



                <Route path="/timetable" element={fulltime ? <Timetable /> : <Navigate to="/" />} />
                <Route path="/examtimetable" element={fulltime ? <ExamTimetable /> : <Navigate to="/" />} />
                <Route path="/absence" element={fulltime ? <Absence /> : <Navigate to="/" />} />


                <Route path="/dcexam" element={distance ? <DCExamDash /> : <Navigate to="/" />} />

                <Route path="/dcexam/apply" element={distance ? <DCApply /> : <Navigate to="/" />} />
                <Route path="/dcexam/return/:id" element={authIsReady ? <DCReturn /> : <Navigate to="/" />} />
                <Route path="/dcexam/status" element={distance ? <DCEntryStatus /> : <Navigate to="/" />} />
                <Route path="/mts" element={distance ? <MTS /> : <Navigate to="/" />} />




              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        )
      }

    </>
  );
}

export default App;
