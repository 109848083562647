import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import { styled, makeStyles } from '@mui/material/styles';
import { Container, Grid, TextField, Button, Typography, createTheme } from '@mui/material'



import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import DashboardIcon from '@mui/icons-material/Dashboard';
import Menu from '@mui/material/Menu'
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoginIcon from '@mui/icons-material/Login';
import ArticleIcon from '@mui/icons-material/Article';
const rightLink = {
    fontSize: 16,
    color: 'common.white',
    ml: 3,
};


const Toolbar = styled(MuiToolbar)(({ theme }) => ({
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
        height: '70',
    },
}));




function AppAppBar2() {
    const navigate = useNavigate()
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }
    return (
        <div>
            <MuiAppBar elevation={0} position="fixed" sx={{ bgcolor: '#2b3a42', overflow: 'auto', height: 'calc(15vh)' }} >

                <Toolbar sx={{ justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', l: 'none', xl: 'none' }, justifyContent: 'flex-start', margin: '10px' }}>

                        <IconButton
                            size="Large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block' },
                        }}
                    >

                        <MenuItem onClick={(event) => [navigate('/'), handleCloseNavMenu()]}>
                            <DashboardIcon />
                            <Typography textAlign="center">Home</Typography>
                        </MenuItem>

                        <MenuItem onClick={(event) => [navigate('/register'), handleCloseNavMenu()]}>
                            <AppRegistrationIcon />
                            <Typography textAlign="center">Register</Typography>
                        </MenuItem>

                        <MenuItem onClick={(event) => [navigate('/login'), handleCloseNavMenu()]}>
                            <LoginIcon />
                            <Typography textAlign="center">Login</Typography>
                        </MenuItem>
                    </Menu>
                    <Box sx={{ flex: 1, display: { xs: 'flex', sm: 'flex' }, justifyContent: 'flex-start' }}>  <img src="./BadgeWhite.png" height={'90vh'} /></Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Typography

                            variant="h3"
                            component="h1"
                            underline="none"
                            fontFamily="Times New Roman"
                            fontWeight={'bold'}
                            fontSize={'30px'}
                            sx={{ marginLeft: '12px' }}

                        >
                            British International Colleges and Schools
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: 'flex', sm: 'none' } }}>
                        <Typography

                            variant="h3"
                            component="h1"
                            underline="none"
                            fontFamily="Times New Roman"
                            fontWeight={'bold'}
                            fontSize={'20px'}
                            sx={{ marginLeft: '12px' }}

                        >
                            British International Colleges and Schools
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex', borderWidth: '3px', marginLeft: '10px' } }}>
                        <Typography

                            variant="h3"
                            component="h1"
                            underline="none"
                            fontFamily="Times New Roman"
                            fontWeight={'bold'}
                            fontSize={'20px'}


                        >
                            <i><small>-Illuminate et Optimus</small></i>
                        </Typography>

                    </Box>
                    <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex', borderWidth: '3px', marginLeft: '10px' }, justifyContent: 'flex-end' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            underline="none"
                            href="/"
                            sx={{ ...rightLink, borderColor: 'white', borderStyle: 'solid', padding: '10px', minWidth: '80px', borderRadius: '5px', borderWidth: '1px' }}
                        >
                            Home
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            underline="none"
                            href="/register"
                            sx={{ ...rightLink, borderColor: 'white', borderStyle: 'solid', padding: '10px', minWidth: '80px', borderRadius: '5px' }}
                        >
                            Register
                        </Button>

                        <Button
                            color="secondary"
                            variant="contained"
                            underline="none"
                            href="/login"
                            sx={{ ...rightLink, borderColor: 'white', borderStyle: 'solid', padding: '10px', minWidth: '80px', borderRadius: '5px' }}
                        >
                            Login
                        </Button>

                    </Box>
                </Toolbar>
            </MuiAppBar>
            <Toolbar />
        </div>
    );
}
export default AppAppBar2