import { useState, useEffect } from 'react'
import { Container, Grid, TextField, Button, Typography, createTheme, Box, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import AppAppBar2 from '../components/AppBar2'
import ProductHeroLayout from '../components/ProductHeroLayout';
import { minHeight, palette } from '@mui/system'
import { useFetchNoUser } from '../hooks/useFetchNoUser'
import CircularProgress from '@mui/material/CircularProgress';
import { useOnMount } from '@mui/x-data-grid/internals';
export default function Conduct() {

    const images = ['../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg'];


    const [backgroundImage, setBackgroundImage] = useState('../img/BannerDistance.jpg')




    const navigate = useNavigate()









    return (

        <>

            <AppAppBar2 />
            <ProductHeroLayout
                fullWidth
                sxBackground={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundColor: '#7fc7d9', // Average color of the background image.
                    fullWidth: '90vh',
                    transition: 'background 3s linear',
                }}
            >

                <Container maxWidth="lg" disableGutters align="center" top="50%" sx={{ overflow: 'scroll', maxHeight: '90vh', backgroundColor: 'white', opacity: '0.8', border: 'Solid', borderWidth: '5px', borderColor: 'primary.main', padding: '30px', borderRadius: 3, marginTop: 3 }}>

                    <Box mb={3} />
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                CODE OF CONDUCT
                            </Typography>



                        </Box>


                    </Grid>
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                <b>All students and parents on our campus or attending one of our exam venues must
                                    abide by <a href="./conduct">British International Colleges Code of Conduct</a> and <a href="./cierules">Cambridge International Examination Rules and Regulations</a>. </b>
                            </Typography>



                        </Box>


                    </Grid>
                    <Box fullWidth
                        sxBackground={{

                            backgroundColor: 'secondary ', // Average color of the background image.
                            fullWidth: 'calc(85vh)',
                            transition: 'background 3s linear',
                            marginTop: 0,


                        }}>



                        <Box sx={{ display: { xs: 'flex', md: 'flex', l: 'flex', xl: 'flex' }, justifyContent: 'center', margin: '10px' }}>

                            <Button
                                color="secondary"

                                variant="contained"
                                underline="none"
                                href="./CodeOfConduct.pdf"
                                target="_blank"

                            >
                                Download / View
                            </Button>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex', l: 'flex', xl: 'flex' }, justifyContent: 'center', margin: '10px' }}>

                            <object data="./CodeOfConduct.pdf" type="application/pdf" height="800px" width="90%" />


                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none', l: 'none', xl: 'none' }, justifyContent: 'center', margin: '10px' }}>

                            <object data="./CodeOfConduct.pdf" type="application/pdf" height="400px" width="90%" />


                        </Box>

                        <Box mb={15} />
                    </Box>
                </Container>

            </ProductHeroLayout>
        </>
    )
}
