import * as React from 'react';
import ProductHeroLayout from '../components/ProductHeroLayout';
import { Container, Grid, TextField, Button, Typography, createTheme, Box, Link } from '@mui/material'
import { useState, useEffect } from 'react'
import { useLogin } from '../hooks/useLogin'
import { useNavigate } from 'react-router-dom'
import { palette } from '@mui/system'
import AppAppBar2 from '../components/AppBar2'
import CircularProgress from '@mui/material/CircularProgress';
import { useFetchNoUser } from '../hooks/useFetchNoUser'
import LoginIcon from '@mui/icons-material/Login';
export default function Login() {
  const images = ['../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg'];


  const [backgroundImage, setBackgroundImage] = useState('../img/BannerBryanston.jpg')

  const { postData, error: error2, errorMessage, data, isPending: isPending2 } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/logincheck', 'POST')




  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundImage(images[Math.floor(Math.random() * images.length)]);
    }, 9000)

    return () => clearInterval(intervalId);
  }, [])



  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()

    postData({ email })
  }

  useEffect(() => {
    if (data) {
      login(email, password)
    }
  }, [data])


  return (


    <>

      <AppAppBar2 />

      <ProductHeroLayout
        fullWidth
        sxBackground={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: '#7fc7d9', // Average color of the background image.
          fullWidth: '90vh',
          transition: 'background 3s linear',
        }}
      >
        {/* Increase the network loading priority of the background image. */}
        <img
          style={{ display: 'none' }}
          src={backgroundImage}
          alt="increase priority"
        />
        <Box mb={5} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"

        >
          <Container maxWidth="xs" disableGutters align="center" top="50%" sx={{ backgroundColor: 'white', opacity: '0.7', border: 'Solid', borderWidth: '5px', borderColor: 'primary.main', padding: '30px', borderRadius: 3, marginTop: 3 }}>

            <form onSubmit={handleSubmit} >
              <Box mb={5} />
              {/* <img src='./BIC_Logo.png' /> */}

              <Box mb={3} />
              <Typography
                variant="h3"
                color="textSecondary"
                component="h2"
                gutterBottom

              >
                Parent Portal
              </Typography>
              <Box mb={2} />
              <Typography
                variant="h3"
                color="textSecondary"
                component="h2"
                gutterBottom

              >
                Login
              </Typography>
              <Typography
                variant="paragraph"
                color="textSecondary"
                component="p"
                gutterBottom
                fontFamily={'Arial'}

              >
                Please enter your username and password.
              </Typography>

              <Box mb={3} />
              {!isPending && !isPending2 && <TextField
                id="outlined-basic"
                label="Username:"
                variant="outlined"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                fullWidth
                autoComplete='email'
                autoFocus
              />}


              {(isPending || isPending2) && <TextField
                id="outlined-basic"
                label="Email Address:"
                variant="outlined"
                type="email"
                required
                disabled
                value={email}
                fullWidth
                autoComplete='email'
                autoFocus
              />}


              <Box mb={2} />

              {!isPending && !isPending2 &&
                <TextField
                  id="outlined-basic"
                  label="Password:"
                  variant="outlined"
                  required
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  fullWidth
                />}
              {(isPending || isPending2) &&
                <TextField
                  id="outlined-basic"
                  label="Password:"
                  variant="outlined"
                  required
                  type="password"
                  disabled
                  value={password}
                  fullWidth
                />}


              <Box mb={2} />
              {error2 && !errorMessage && <p className='error'>Service currently offline. Please try again later.</p>}
              {error && error !== "auth/invalid-credential" && <p className='error'>{error}</p>}
              {error && error === "auth/invalid-credential" && <p className='error'>Authentication failed. Please enter a valid username and password. </p>}
              {errorMessage && <p className='error'>{errorMessage}</p>}
              <Box mb={2} />
              {!isPending && !isPending2 && <Button variant="contained" type="submit" fullWidth>Login <LoginIcon /></Button>}
              {(isPending || isPending2) && <Button variant="contained" disabled fullWidth>Logging in...<CircularProgress /></Button>}
              <Box mb={2} />


              <Typography
                variant="paragraph"
                color="textSecondary"
                component="p"
                gutterBottom
                button
                sx={{ TextDecoration: 'none', color: 'secondary.main' }}
              >


                {!isPending && !isPending2 && <Link href="../forgotpassword">Forgot password?</Link>}



              </Typography>
              <Box mb={2} />




              <Box mb={2} />

            </form>
          </Container>


        </Box>
        <Typography variant="body2" color="inherit" sx={{ mt: 2, fontStyle: 'italic' }}>
          <a class="buttonIllum"><span> Illuminate et Optimus</span></a>
        </Typography>
        <Typography variant="body2" color="inherit" sx={{ bottom: 0, marginTop: 'auto', mt: 2, fontStyle: 'italic', color: 'white', textDecoration: 'none' }}>
          View website <a style={{ color: 'white' }} href='./tnc' target='_blank'>Terms and Conditions</a>
        </Typography>
      </ProductHeroLayout>

    </>
  )
}
