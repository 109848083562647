import { useState, useEffect } from 'react'
import { Divider, Container, Grid, TextField, Button, Typography, createTheme, Box, Link, InputLabel, FormControl, Select, MenuItem, OutlinedInput } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import AppAppBar2 from '../components/AppBar2'
import ProductHeroLayout from '../components/ProductHeroLayout';
import { palette } from '@mui/system'
import { useFetchNoUser } from '../hooks/useFetchNoUser'
import CircularProgress from '@mui/material/CircularProgress';
import validator from 'validator'
import { NatOutlined } from '@mui/icons-material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';



export default function RegisterUser() {


    const [ipAddress, setIPAddress] = useState('')

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIPAddress(data.ip))
            .then(console.log("My ip address is " + ipAddress))
            .catch(error => console.log(error))
    }, [ipAddress]);

    const [backgroundImage, setBackgroundImage] = useState('../img/BannerBryanston.jpg')

    const { postData, error, errorMessage, data, isPending } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/sendotp', 'POST')
    const { postData: postData2, error: error2, errorMessage: errorMessage2, data: data2, isPending: isPending2 } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/verifyotp', 'POST')



    const { postData: postData3, error: error3, errorMessage: errorMessage3, data: data3, isPending: isPending3 } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/acceptterms', 'POST')
    const { postData: postData4, error: error4, errorMessage: errorMessage4, data: data4, isPending: isPending4 } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/create', 'POST')

    //
    // 
    //  const { postData: postData2, error: error2, errorMessage: errorMessage2, data: data2, isPending: isPending2 } = useFetchNoUser('https://studentapi.bi-oss.co.za/user/terms', 'POST')




    const navigate = useNavigate()
    const [email, setEmail] = useState('')

    const [succ, setSucc] = useState('')



    const [viewOTP, setViewOTP] = useState(0)
    const [viewTerms, setViewTerms] = useState(0)
    const [viewPass, setViewPass] = useState(0)
    const [viewDecline, setViewDecline] = useState(0)
    const [password, setPassword] = useState('')
    const [conf1, setConf1] = useState(false)
    const [conf2, setConf2] = useState(false)
    const [conf3, setConf3] = useState(false)
    const [resend, setResend] = useState(0)
    const [otp, setOTP] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        setSucc('')
        postData({
            email,

        })
    }

    useEffect(() => {
        if (data) {
            setViewOTP(1)

        }
    }, [data])




    const resendNewOTP2 = (e) => {
        postData({
            email,

        })
        setResend(1)
    }
    const handleSubmit2 = (e) => {
        e.preventDefault()
        postData2({
            email,
            otp
        })
    }


    useEffect(() => {
        if (data2) {
            setViewOTP(0)
            setViewTerms(1)
        }
    }, [data2])


    const handleSubmit3 = (e) => {
        e.preventDefault()
        postData3({
            email,
            ipAddress
        })
    }
    const handleDecline = (e) => {
        setViewTerms(0)
        setViewDecline(1)
    }


    useEffect(() => {
        if (data3) {
            setViewTerms(0)
            setViewPass(1)
        }
    }, [data3])


    const [errorPass, setErrorPass] = useState('')
    const [successPass, setSuccessPass] = useState('')
    const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorPass('')
            setSuccessPass('Password strength: Secure.')
            setPassword(value)

        } else {
            setSuccessPass('')
            setErrorPass('Password strength: Weak.')

        }
    }

    const handleSubmit4 = (e) => {
        e.preventDefault()
        postData4({ email, password })
    }


    useEffect(() => {
        if (data4) {
            setViewPass(0)
            setSucc(1)
        }
    }, [data4])


    return (

        <>

            <AppAppBar2 />

            <ProductHeroLayout
                fullWidth
                sxBackground={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundColor: '#7fc7d9', // Average color of the background image.
                    fullWidth: '90vh',
                    transition: 'background 3s linear',
                }}
            >
                {/* Increase the network loading priority of the background image. */}

                <Box mb={5} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                >


                    <Container maxWidth="sm" disableGutters align="center" top="50%" sx={{ backgroundColor: 'white', opacity: '0.9', border: 'Solid', borderWidth: '5px', borderColor: 'primary.main', padding: '30px', borderRadius: 3, marginTop: 3 }}>

                        <Box mb={10} />

                        {!viewTerms && !viewDecline && !viewOTP && !viewPass && !succ && (

                            <>


                                <form onSubmit={handleSubmit}>
                                    <Box mb={5} />
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}>
                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                <Typography
                                                    variant="h3"
                                                    color="textSecondary"
                                                    component="h2"
                                                    gutterBottom

                                                >
                                                    Register
                                                </Typography>
                                            </Box>


                                        </Grid>



                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    color="textSecondary"
                                                    component="p"
                                                    gutterBottom
                                                    fontFamily={'Arial'}

                                                >
                                                    Parents of currently registered BIC, BIDC and BIP students can create a parent portal account.
                                                </Typography>
                                            </Box>


                                        </Grid>

                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    color="textSecondary"
                                                    component="p"
                                                    gutterBottom
                                                    fontFamily={'Arial'}

                                                >
                                                    To create an account, parents must simply enter their email address as supplied during student registration. Once verified, you will be able to complete your account creation.


                                                </Typography>
                                            </Box>


                                        </Grid>


                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >

                                                {!succ &&
                                                    <TextField
                                                        id="emailbox"
                                                        label="Email Address:"
                                                        variant="outlined"
                                                        type="email"
                                                        required
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        value={email}
                                                        fullWidth
                                                        autoComplete='email'
                                                        autoFocus
                                                    />}


                                                {succ &&
                                                    <TextField
                                                        id="emailbox"
                                                        label="Email Address:"
                                                        variant="outlined"
                                                        type="email"
                                                        required
                                                        disabled
                                                        value={email}
                                                        fullWidth
                                                        autoComplete='email'
                                                        autoFocus

                                                    />}


                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                {error && !errorMessage && <p className='error'>Service currently offline. Please try again later.</p>}
                                                {errorMessage && <p className='error'>{errorMessage}</p>}
                                                {succ && <p className='success'>{succ}</p>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >

                                                {!isPending && !succ && <Button variant="contained" type="submit" fullWidth>Submit</Button>}

                                                {isPending && <Button variant="contained" disabled fullWidth><CircularProgress /></Button>}
                                            </Box>
                                        </Grid>




                                        <Grid item xs={12} >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                <Link href="../login">Login?</Link>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </form>

                            </>
                        )}

                        {viewOTP === 1 && (

                            <>

                                <form onSubmit={handleSubmit2}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} >
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Typography
                                                    variant="h4"
                                                    color="textSecondary"
                                                    component="h4"
                                                    gutterBottom

                                                >
                                                    Register Account
                                                </Typography>

                                            </Box></Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="textSecondary"
                                                    component="h5"
                                                    gutterBottom

                                                >
                                                    Verification
                                                </Typography>
                                            </Box></Grid>
                                        <Grid item xs={12} >

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Typography
                                                    variant="paragraph"
                                                    color="textSecondary"
                                                    component="p"
                                                    gutterBottom
                                                    fontFamily={'Arial'}

                                                >
                                                    An OTP has been sent to {email}.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    color="textSecondary"
                                                    component="p"
                                                    gutterBottom
                                                    fontFamily={'Arial'}

                                                >
                                                    Please enter the OTP below to verify your account.
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        sx={{

                                                            maxWidth: '95%',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto'
                                                        }}
                                                    >
                                                        <TextField
                                                            id="otp"
                                                            label="Enter OTP:"
                                                            variant="outlined"
                                                            type="numbers"
                                                            required
                                                            onChange={(e) => setOTP(e.target.value)}
                                                            value={otp}
                                                            fullWidth

                                                            autoFocus
                                                            inputProps={{ style: { textAlign: 'center' } }}
                                                        />

                                                    </Box>
                                                </Grid>

                                            </Box>
                                        </Grid>



                                        {error2 && !errorMessage2 &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <p className='error'>Service currently offline. Please try again later.</p>
                                                </Box>
                                            </Grid>
                                        }
                                        {errorMessage2 &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <p className='error'>{errorMessage2}</p>
                                                </Box>
                                            </Grid>

                                        }


                                        {!isPending2 &&
                                            <Grid item xs={12} >

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            sx={{

                                                                maxWidth: '95%',
                                                                marginLeft: 'auto',
                                                                marginRight: 'auto'
                                                            }}
                                                        >
                                                            <Button fullWidth variant="contained" type="submit">Submit OTP</Button>
                                                        </Box></Grid>
                                                </Box></Grid>}

                                        {isPending2 &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" disabled fullWidth><CircularProgress /></Button>
                                                </Box>
                                            </Grid>
                                        }

                                        {!resend && (
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="paragraph"
                                                        color="textSecondary"
                                                        component="p"
                                                        gutterBottom
                                                        fontFamily={'Arial'}

                                                    >
                                                        OR
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                        )}


                                        {(!isPending || !isPending) && !resend &&


                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            sx={{

                                                                maxWidth: '95%',
                                                                marginLeft: 'auto',
                                                                marginRight: 'auto'
                                                            }}
                                                        >
                                                            <Button variant="contained" onClick={(e) => resendNewOTP2()} fullWidth>Resend OTP</Button>
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        }
                                        {resend === 1 && (

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="paragraph"
                                                        color="textSecondary"
                                                        component="p"
                                                        gutterBottom
                                                        fontFamily={'Arial'}

                                                    >
                                                        OTP resent via email to {email}
                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        )}

                                    </Grid>
                                </form>
                            </>
                        )}



                        {viewTerms === 1 && (

                            <>




                                <form onSubmit={handleSubmit3}>
                                    <Box mb={5} />
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} style={{
                                        maxHeight: "100vh",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "440px",
                                        overflow: "auto",
                                    }}>



                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Typography
                                                    variant="h4"
                                                    color="textSecondary"
                                                    component="h4"
                                                    gutterBottom

                                                >
                                                    Terms and Conditions
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="textSecondary"
                                                    component="h5"
                                                    gutterBottom

                                                >
                                                    Agreement 1:
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    To view our Terms and Conditions, visit:

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    <a href="https://parent.bi-oss.co.za/tnc" target='blank'>https://parent.bi-oss.co.za/tnc</a>.

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                                    By clicking agree, I state that I have read, understood and agree to all the "Terms and Conditions" in the above link.

                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Stack direction="row" spacing={12} sx={{ textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>

                                                    {conf1 === false && (
                                                        <>

                                                            <Typography sx={{ textAlign: 'left', color: 'error.main' }}>I <u>Decline</u> </Typography>
                                                            <Switch checked={false} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf1(true)} name="I Decline" />
                                                            <Typography sx={{ textAlign: 'right', color: 'primary.main' }}>I <u>Agree</u> </Typography>
                                                        </>

                                                    )}
                                                    {conf1 === true && (
                                                        <>

                                                            <Typography sx={{ textAlign: 'left', color: 'primary.main' }}>I <u>Decline</u> </Typography>
                                                            <Switch sx={{ textAlign: 'center' }} color="success" checked={true} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf1(false)} name="I Agree" />
                                                            <Typography sx={{ textAlign: 'right', color: 'success.main' }}>I <u>Agree</u> </Typography>
                                                        </>

                                                    )}



                                                </Stack>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Divider style={{ width: '100%', color: "primary.main" }} />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="textSecondary"
                                                    component="h5"
                                                    gutterBottom

                                                >
                                                    Agreement 2:
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    To view the Code of Conduct visit:

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    <a href="https://parent.bi-oss.co.za/conduct" target='blank'>https://parent.bi-oss.co.za/conduct</a>.

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                                    By clicking agree, I state that I have read, understood and agree to the "Code of Conduct" in the above link.

                                                </Typography>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >


                                                <Stack direction="row" spacing={12} sx={{ textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>

                                                    {conf2 === false && (
                                                        <>
                                                            <Typography sx={{ textAlign: 'left', color: 'error.main' }}>I <u>Decline</u> </Typography>
                                                            <Switch checked={false} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf2(true)} name="I Decline" />
                                                            <Typography sx={{ textAlign: 'right', color: 'primary.main' }}>I <u>Agree</u> </Typography>
                                                        </>

                                                    )}
                                                    {conf2 === true && (
                                                        <>
                                                            <Typography sx={{ textAlign: 'left', color: 'primary.main' }}>I <u>Decline</u> </Typography>
                                                            <Switch sx={{ textAlign: 'center' }} color="success" checked={true} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf2(false)} name="I Agree" />
                                                            <Typography sx={{ textAlign: 'right', color: 'success.main' }}>I <u>Agree</u> </Typography>
                                                        </>

                                                    )}


                                                </Stack>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Divider style={{ width: '100%', color: "primary.main" }} />
                                            </Box></Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="textSecondary"
                                                    component="h5"
                                                    gutterBottom

                                                >
                                                    Agreement 3:
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    To view the Cambridge International Examination Rules and Regulations:

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    <a href="https://parent.bi-oss.co.za/cierules" target='blank'>https://parent.bi-oss.co.za/cierules</a>.

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                                    By clicking agree, I state that I have read, understood and agree to all the "Cambridge International Examination Rules and Regulations" in the above link.

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Stack direction="row" spacing={12} sx={{ textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>

                                                    {conf3 === false && (
                                                        <>
                                                            <Typography sx={{ textAlign: 'left', color: 'error.main' }}>I <u>Decline</u> </Typography>
                                                            <Switch checked={false} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf3(true)} name="I Decline" />
                                                            <Typography sx={{ textAlign: 'right', color: 'primary.main' }}>I <u>Agree</u> </Typography>
                                                        </>

                                                    )}
                                                    {conf3 === true && (
                                                        <>

                                                            <Typography sx={{ textAlign: 'left', color: 'primary.main' }}>I <u>Decline</u> </Typography>
                                                            <Switch sx={{ textAlign: 'center' }} color="success" checked={true} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf3(false)} name="I Agree" />
                                                            <Typography sx={{ textAlign: 'right', color: 'success.main' }}>I <u>Agree</u> </Typography>
                                                        </>

                                                    )}


                                                </Stack>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Divider style={{ width: '100%', color: "primary.main" }} />

                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography sx={{ textAlign: 'center', color: 'primary.main' }}>* <i><b>Please Note: </b>The above "Cambridge International Examination Rules and Regulations", "Code of Conduct", "Terms and Conditions" are all subject to change.</i> </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                {error3 && !errorMessage3 && <p className='error'>Service currently offline. Please try again later.</p>}
                                                {errorMessage3 && <p className='error'>{errorMessage3}</p>}
                                            </Box>
                                        </Grid>

                                        {!isPending3 &&

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" type="submit" fullWidth>Accept</Button>
                                                </Box></Grid>
                                        }

                                        {isPending3 &&

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" disabled fullWidth><CircularProgress /></Button>
                                                </Box>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="textSecondary"
                                                    component="h5"
                                                    gutterBottom

                                                >
                                                    OR
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        {!isPending3 &&

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" onClick={(e) => handleDecline()} fullWidth>Decline</Button>
                                                </Box>
                                            </Grid>
                                        }



                                    </Grid>

                                    <Box mb={5} />
                                </form>


                            </>

                        )}
                        {viewDecline === 1 && (

                            <>

                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} >

                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center" f
                                            alignItems="center"
                                            textAlign="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'

                                            }}
                                        >

                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                component="h4"
                                                gutterBottom

                                            >
                                                Terms and Conditions
                                            </Typography>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center" f
                                            alignItems="center"
                                            textAlign="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'

                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                You have declined our terms and conditions.

                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center" f
                                            alignItems="center"
                                            textAlign="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'

                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                Account registration has been cancelled.

                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center" f
                                            alignItems="center"
                                            textAlign="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'

                                            }}
                                        >
                                            <Button variant="contained" href="/" fullWidth>Home Page</Button>
                                        </Box>
                                    </Grid>


                                </Grid>


                            </>
                        )}



                        {viewPass === 1 && (

                            <>

                                <form onSubmit={handleSubmit4}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} >




                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="textSecondary"
                                                    component="h5"
                                                    gutterBottom

                                                >
                                                    Create Account
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Typography
                                                    variant="paragraph"
                                                    color="textSecondary"
                                                    component="p"
                                                    gutterBottom
                                                    fontFamily={'Arial'}

                                                >
                                                    Please enter a password for your account.
                                                </Typography>

                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <Typography
                                                    variant="paragraph"
                                                    color="textSecondary"
                                                    component="p"
                                                    gutterBottom
                                                    fontFamily={'Arial'}

                                                >
                                                    Passwords must be alphanumeric, containing both uppercase and lowercase letters, special characters and numbers with a minimum of 8 characters.
                                                </Typography>

                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <TextField

                                                    id="password"
                                                    label="Enter New Password"
                                                    onChange={(e) => validate(e.target.value)}

                                                    fullWidth
                                                    required

                                                    type="password"
                                                    autoComplete='nope'
                                                    inputProps={{ maxLength: 100, minLength: 8, style: { textAlign: 'center' } }}
                                                />

                                            </Box>
                                        </Grid>



                                        {errorPass &&

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <p className='error'>{errorPass}</p>
                                                </Box>
                                            </Grid>
                                        }
                                        {successPass && (

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <TextField

                                                        id="Notification"
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        value={successPass}
                                                        fullWidth
                                                        required
                                                        sx={{ borderRadius: 1, backgroundColor: "success.main", input: { color: 'white', textAlign: 'center' } }}
                                                    />

                                                </Box>
                                            </Grid>
                                        )}


                                        {error4 && !errorMessage4 &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >

                                                    <p className='error'>Service currently offline. Please try again later.</p>
                                                </Box>
                                            </Grid>
                                        }
                                        {errorMessage4 &&

                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <p className='error'>{errorMessage4}</p>
                                                </Box>

                                            </Grid>
                                        }


                                        {!isPending4 && !errorPass &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" type="submit" fullWidth>Create Account</Button>
                                                </Box>
                                            </Grid>
                                        }
                                        {errorPass &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" disabled fullWidth>Create Account</Button>
                                                </Box>
                                            </Grid>
                                        }
                                        {isPending4 &&
                                            <Grid item xs={12}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <Button variant="contained" disabled fullWidth><CircularProgress /></Button>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>





                                    <Box mb={5} />
                                </form>


                            </>
                        )}


                        {succ === 1 && (
                            <>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Box mb={5} />


                                    <form >
                                        <Box mb={5} />
                                        <Box mb={3} />
                                        <Typography
                                            variant="h3"
                                            color="textSecondary"
                                            component="h2"
                                            gutterBottom

                                        >
                                            Registration
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            color="textSecondary"
                                            component="h2"
                                            gutterBottom

                                        >
                                            Complete
                                        </Typography>
                                        <Box mb={2} />


                                        <Typography
                                            variant="paragraph"
                                            color="success.main"
                                            component="p"
                                            gutterBottom
                                            fontFamily={'Arial'}

                                        >
                                            Your account has been created. Please login.
                                        </Typography>

                                        <Box mb={2} />


                                        <Button variant="contained" href="../login" fullWidth>Login</Button>





                                        <Box mb={2} />
                                    </form>



                                </Box>



                            </>

                        )}
                    </Container>
                </Box>
                <Typography variant="body2" color="inherit" sx={{ mt: 2, fontStyle: 'italic' }}>
                    <p class="buttonIllum"><span> Illuminate et Optimus</span></p>
                </Typography>
                <Typography variant="body2" color="inherit" sx={{ bottom: 0, marginTop: 'auto', mt: 2, fontStyle: 'italic', color: 'white', textDecoration: 'none' }}>
                    View website <a style={{ color: 'white' }} href='./tnc' target='_blank'>Terms and Conditions</a>
                </Typography>


            </ProductHeroLayout >

        </>
    )
}
