import { useState, useEffect } from 'react'
import { Container, Grid, TextField, Button, Typography, createTheme, Box, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import AppAppBar2 from '../components/AppBar2'
import ProductHeroLayout from '../components/ProductHeroLayout';
import { minHeight, palette } from '@mui/system'
import { useFetchNoUser } from '../hooks/useFetchNoUser'
import CircularProgress from '@mui/material/CircularProgress';
export default function Terms() {


    const images = ['../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg'];


    const [backgroundImage, setBackgroundImage] = useState('../img/BannerBryanston.jpg')




    const navigate = useNavigate()









    return (

        <>

            <AppAppBar2 />
            <ProductHeroLayout
                fullWidth
                sxBackground={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundColor: '#7fc7d9', // Average color of the background image.
                    fullWidth: '90vh',
                    transition: 'background 3s linear',
                }}
            >

                <Box mb={7} />

                <Container maxWidth="lg" disableGutters align="center" top="50%" sx={{ maxHeight: '90vh', overflow: 'scroll', backgroundColor: 'white', opacity: '0.8', border: 'Solid', borderWidth: '5px', borderColor: 'primary.main', padding: '30px', borderRadius: 3, marginTop: 3 }}>


                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '0px', borderColor: 'primary.main', borderRadius: 2 }}>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Terms and Conditions
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Examination Entry
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    <ul>

                                        <li>1) The Examinations Officer will enter applications according to information given on this exam entry application, unless informed otherwise by receipt of a signed Amendment to Entry form.</li>

                                        <li>2) If you wish to carry forward results of a paper from a previous exam session, please confirm that the carry forward is included in the above exam selection. Students may carry forward a language oral grade, or a computer project grade. Please note that these can only be carried forward for one year.</li>

                                        <li>3) All practical and oral exams are to be completed at the Bryanston or Pretoria campus, unless the Examinations Officer specifies that a venue can accommodate the exam.</li>

                                        <li>4) Exam entry fees in full and the application form must be received by the College before entry deadline. Failure to complete this Exam Entry in time may result in Cambridge penalty fees.</li>

                                        <li>5) Additional Examination Session fees will be charged for the use of an alternative venue (i.e. a venue other than a BIC campus) - these include venue hire, invigilation fee, courier/admin costs and are included in the above quote. In the case of a withdrawal from an examination, the examination fees and examination venue fees paid will not be refunded by the alternative exam venue.</li>

                                        <li>6) In the event fewer than 5 (five) candidates enter for a particular alternative venue in a particular examination session, we may be unable to operate that exam venue, and candidates may be transferred to the nearest venue. This does not apply to venues which are existing Cambridge centres.</li>

                                        <li>7) Transfers between venues will only be accepted until 15 April ( in respect of May / June exam sessions ) or 15 September ( in respect of October / November exam sessions ). The first such transfer is at no cost, but any further transfers will incur a fee.</li>

                                        <li>8) Cambridge reserves the right to accept any application for examination entry.</li>
                                    </ul>
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Re-write Entries:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    <ul>

                                        <li>1) If you wish to improve an IGCSE achieved grade of a ‘C’ or better or an 'E' or better at AS or A level, you may simply enter the exam if still registered for the subject in the academic year. If your result was a lower grade, we recommend that you register for a course at BIDC or BIC.</li>


                                    </ul>
                                </Typography>



                            </Box>


                        </Grid>


                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Examination Information:


                                </Typography>



                            </Box>


                        </Grid>





                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    The deadline to enter to write exams in May/June is 31 January.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    The deadline to enter to write exams in Oct/Nov is 31 May.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    <b>Note:</b>

                                    <ul>

                                        <li>IGCSE Physical Science and all Afrikaans examinations are only offered by Cambridge in November.</li>
                                        <li>Art and Design coursework and examination submissions will not be automatically returned by Cambridge. Students must apply to have their work returned for a fee.</li>
                                    </ul>
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Exam Entry:


                                </Typography>



                            </Box>


                        </Grid>


                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>


                                    <ul>

                                        <li>Examination entry can only be processed on receipt of both the examination entry application form and the examination fees.</li>
                                        <li>Late entries are subject to penalty fees levied by Cambridge.</li>
                                        <li>Examination fees cannot be transferred to another sitting or refunded after entry, unless refunded by Cambridge for very specific reasons.</li>

                                        <li>All examination queries are to be forwarded to the Private Canidate team. An Academic Consultation maybe required to assist with your query.</li>
                                        <li>Dates of examinations are set by Cambridge and cannot be changed.</li>
                                        <li>Applications for special arrangements (e.g. extra time) must accompany the exam entry form in writing.</li>
                                        <li>Should permission be granted for students to write at any one of our examination venues, additional fees are incurred for invigilation, venue hire and administration.</li>
                                    </ul>
                                </Typography>



                            </Box>


                        </Grid>


                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Alternative Examination Venues:


                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>


                                    <ul>

                                        <li>The approval of alternative venues (other than Bryanston or Pretoria campus) is solely at the discretion of Cambridge and may vary from year to year.</li>
                                        <li>All practical and oral components can only be examined at the Bryanston Campus or Pretoria Campus.</li>
                                    </ul>
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Website Terms and Conditions


                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    These Terms and Conditions ("the Terms and Conditions") govern your ("the User") use of the British International College ("Provider") website located at the domain name BIOSS.CO.ZA ("the Website"). By accessing and using the Website, the User agrees to be bound by the Terms and Conditions set out in this legal notice. The User may not access, display, use, download, and/or otherwise copy or distribute content obtained on the website for marketing or other purposes without the express written consent of the Provider.
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Electronic Communications:

                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    By using this Website or communicating with the Provider by electronic means, the user consents and acknowledges that any and all agreements, notices, disclosures, or any other communication satisfies any legal requirement, including but not limited to the requirement that such communications should be in writing..


                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    E-Commerce & Privacy:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    The Website (https://privatecandidate.bi-oss.co.za) sells [Online Cambridge Exam Entry/ Tuition/ Courses] online. The use of any product or service bought from this Website is at the Users risk. The User indemnifies and holds the Provider harmless against any loss, injury or damages which may be sustained as a result of using the products sold on this Website. The private information required for executing orders placed through this e-commerce facility, namely the User’s personal information and credit card details, delivery address and telephone numbers will be kept in the strictest confidence by the Provider and will not be sold or made known to third parties. Only the necessary information, that is the delivery address and contact phone number, will be made known to third parties delivering the product. Credit card details are not retained by the Provider under any circumstances.

                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    The Provider cannot be held responsible for security breaches occurring on the User’s electronic device (Personal Computer or other device) used to browse the Website which may result due to the lack of adequate virus protection software or spyware that the User may inadvertently have installed on his/her device. The Provider will supply all goods to the delivery company in good order. The Provider will not be held liable for the condition of goods arriving at the User’s chosen delivery address.
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Online Payment
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    All online credit card payments are processed by the Paygate Internet Payment Gateway. Card Holders may go to www.paygate.co.za to view MyGate’s security policies.
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Refund and Return Policy:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    Candidate entry fees and all administrative fees are non-refundable.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    Cambridge examination fees are only refunded once Cambridge have issued the refund at the end of each examination session.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    Any complaints regarding the standard and quality of the product or products bought by consumers through this e-commerce facility should be directed to the Private Candidate Team, [privatecandidates@bicollege.co.za].

                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Updating of these Terms and Conditions:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    Provider reserves the rights to change, modify, add or remove from portions or the whole of these Terms and Conditions from time to time. Changes to these Terms and Conditions will become effective upon such changes being posted to this Website. It is the User’s obligation to periodically check these Terms and Conditions at the Website for changes or updates. The User’s continued use of this Website following the posting of changes or updates will be considered notice of the User’s acceptance to abide by and be bound by these Terms and Conditions, including such changes or updates.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Copyright and Intellectual Property Rights:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>

                                    Provider offers certain information at the Website. Content currently or anticipated to be displayed at this Website is provided by Provider, its affiliates and/or subsidiary, or any other third party owners of such content, and includes but is not limited to Literary Works, Musical Works, Artistic Works, Sound Recordings, Cinematograph Films, Sound and Television Broadcasts, Program-Carrying Signals, Published Editions and Computer Programs ("the Content"). All such proprietary works, and the compilation of the proprietary works, are copyright the Provider, its affiliates or subsidiary, or any other third party owner of such rights ("the Owners"), and is protected by South African and international copyright laws. The Providers reserve the right to make any changes to the Website, the Content, or to products and/or services offered through the Website at any times and without notice. All rights in and to the Content is reserved and retained by the Owners. Except as specified in these Terms and Conditions, the User is not granted a license or any other right including without limitation under Copyright, Trademark, Patent or other Intellectual Property Rights in or to the Content.
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Limitation of liability:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    The Website and all Content on the Website, including any current or future offer of products or services, are provided on an "as is" basis, and may include inaccuracies or typographical errors. The Provider makes no warranty or representation as to the availability, accuracy or completeness of the Content. Neither Provider nor any holding company, affiliate or subsidiary of Provider, shall be held responsible for any direct or indirect special, consequential or other damage of any kind whatsoever suffered or incurred, related to the use of, or the inability to access or use the Content or the Website or any functionality thereof, or of any linked website, even if Provider is expressly advised thereof.
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Privacy: casual surfing:
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    The User may visit the Website without providing any personal information. The Website servers will in such instances collect the IP address of the User computer, but not the email address or any other distinguishing information. This information is aggregated to measure the number of visits, average time spent at the Website, pages viewed, etc. Provider uses this information to determine use of the Website, and to improve Content thereon. Provider assumes no obligation to protect this information, and may copy, distribute or otherwise use such information without limitation.

                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'left' }}>
                                    Choice of Law:


                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="left"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                    This Website is controlled, operated and administered by Provider from its offices within the Republic of South Africa. Access to the Website from territories or countries where the Content or purchase of the products sold on the Website is illegal is prohibited. The User may not use this Website in violation of South African export laws and regulations. If the User accesses this Website from locations outside of South Africa, that User is responsible for compliance with all local laws. These Terms and Conditions shall be governed by the laws of the Republic of South Africa, and the User consents to the jurisdiction of the Witwatersrand High Court in the event of any dispute. If any of the provisions of these Terms and Conditions are found by a court of competent jurisdiction to be invalid or unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of these Terms and Conditions, and the remainder of these Terms and Conditions shall continue in full force and effect. These Terms and Conditions constitute the entire agreement between the Provider and the User with regard to the use of the Content and this Website.
                                </Typography>



                            </Box>


                        </Grid>

                    </Grid>

                </Container>
            </ProductHeroLayout>
        </>
    )
}
